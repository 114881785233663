.lm-login {
    width: 100%;
    max-width: 480px;
    box-sizing: border-box;
    margin: 0 auto;
    vertical-align:middle;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    font-size: 1em;
    line-height: normal;
}

.lm-login-fullscreen {
    position:absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    background-color:#fff;
    z-index:9000;
}

.lm-login-instructions {
    font-weight: bold;
}

.lm-login > form {
    margin: 30px 0;
}

.lm-login > form > div {
    margin-bottom: 10px;
}

.lm-login label {
    display: block;
}

.lm-login > form > div input {
    width: 100%;
    padding: 12px;
    margin: 0;
    box-sizing: border-box;
}

.lm-login > form .lm-dropdown-input {
    width: 100%;
    padding: 11px;
    margin: 0;
    box-sizing: border-box;
}

.lm-login .lm-login-dropdown {
    display: flex;
}

.lm-login .lm-login-dropdown > div:first-child {
    margin-right: 8px;
}

.lm-login .lm-login-dropdown > div:last-child {
    flex: 1;
}

.lm-login input[type='checkbox'] {
    width: 30px;
    float:left;
}

.lm-login input[type='button'] {
    background-color: var(--lm-main-color, #2196f3);
    border: 1px solid var(--lm-main-color, #2196f3);
    color: #fff;
    cursor:pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.lm-login span  {
    margin:0 auto;
}

.lm-login img  {
    text-align: center;
    max-width: 100%;
    display: block;
}

.lm-login .lm-login-logo {
    text-align: center;
    padding: 20px;
}

.lm-login .lm-login-logo:empty {
    display: none;
}

.lm-login .captcha  {
    width:100%;
    margin-top:4px;
    margin-bottom:4px;
    border:1px solid #ccc;
    display:block;
}

.lm-login input.facebookButton  {
    color: var(--lm-main-color, #2196f3);
    border: 1px solid var(--lm-main-color, #2196f3);
    background-color: #fff;
}

.lm-login input.googleButton  {
    border: 1px solid var(--lm-main-color, #2196f3);
    background-color: #fff;
    color: var(--lm-main-color, #2196f3);
}

.lm-login-button {
    text-align: center;
    padding: 20px 0 0 0;
}

.lm-login-button span {
    cursor: pointer;
}

.lm-login-button span:hover {
    text-decoration: underline;
}

.lm-login-captcha {
    min-width: 280px;
}

.lm-login-captcha img {
    width: 100%;
}

.lm-login-captcha[src=""] {
    display: none;
}

.lm-login-loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 2px;
    background-color: var(--lm-main-color, #2196f3);
    animation: loading 2s linear infinite;
}

@keyframes loading {
    from { left: 0; width: 25%; }
    25%  { width: 25%; }
    50%  { width: 50%; }
    75%  { left:  75%; }
    100% { left:  100%; }
    to { left: 100%; }
}